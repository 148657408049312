import React from "react";
import store from "./storeConfig";

import { Provider } from "react-redux";

const AppProvider: React.FC = (props) => {
  return <Provider {...props} store={store} />;
};

export default AppProvider;
